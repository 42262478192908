.badge {
    padding: 6px 15px;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 600;
    width: fit-content;

    &.bg-primary {
      background-color: $dark-blue-10 !important;
      color: $dark-blue;
    }
  
    &.bg-danger {
      background-color: $dark-orange-10 !important;
      color: $dark-orange;
    }
  
    &.bg-success {
      background-color: $light-green-10 !important;
      color: $light-green;
    }
  
    &.bg-warning {
      background-color: $orange-10 !important;
      color: $orange;
    }
  
    &.bg-info {
      background-color: $light-blue-10 !important;
      color: $light-blue;
    }
  
    &.bg-muted {
      background-color: $dark-gray-10 !important;
      color: $dark-gray-75;
    }
  }