/*-------------------------------------
            Bootstrap Modal
--------------------------------------*/

.modal-content {
    border-radius: 15px;
    border: 0px;
    @include box-shadow2;

    &.transparent {
        background-color: transparent;
        box-shadow: unset;
    }

    .modal-header {
        border-bottom: none !important;
        padding: 30px;
        padding-bottom: 20px;
        background-color: white;
        color: $dark-gray;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        .btn-close:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .modal-body {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .modal-footer {
        border-top: none !important;
        padding: 30px;
        padding-top: 20px;
    }
}




/* -------------------------------------
               Media Query
----------------------------------------*/
@media (max-width: 576px) {

    .modal-content {

        .modal-header,
        .modal-body,
        .modal-footer {
            padding-right: 20px !important;
            padding-left: 20px !important;
        }
    }
}