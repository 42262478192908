/* -------------------------------------
                Colors
----------------------------------------*/
$dark-blue: #161D6F;
$dark-blue-75: #515693;
$dark-blue-50: #8A8EB7;
$dark-blue-25: #C5C6DB;
$dark-blue-10: #E7E8F0;
$dark-blue-5: #F3F3F8;
$dark-blue-second: #000758;

$dark-orange: #F05454;
$dark-orange-75: #F47F7F;
$dark-orange-50: #F7A9A9;
$dark-orange-25: #FBD4D4;
$dark-orange-15: #FDE6E6;
$dark-orange-10: #FDEDED;
$dark-orange-5: #FEF6F6;
$dark-orange-second: #DD3F3F;

$orange: #F6A623;
$orange-75: #F9BD5A;
$orange-50: #FAD291;
$orange-25: #FDE9C8;
$orange-10: #FEF6E9;
$orange-5: #FEFAF4;

$light-blue: #4BBFDD;
$light-blue-75: #78D0E6;
$light-blue-50: #A6DFEE;
$light-blue-25: #D3F0F7;
$light-blue-10: #EDF8FC;
$light-blue-5: #F6FCFE;

$light-green: #23B559;
$light-green-75: #5BC783;
$light-green-50: #91D9AC;
$light-green-25: #C8ECD5;
$light-green-10: #E9F7EE;
$light-green-5: #F4FBF6;

$dark-gray: #2A2A2A;
$dark-gray-75: #606060;
$dark-gray-50: #949494;
$dark-gray-25: #CACACA;
$dark-gray-10: #E9E9E9;

$red-color: #FF5627;
$border-color: #D8D8D8;
$light-gray: #F9F9F9;
$form-bg: #f3f3f4;

/* -------------------------------------
                Box Shadow
----------------------------------------*/
@mixin box-shadow($shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.17)) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin box-shadow2($shadow2: 0px 7px 19px -6px rgba(0, 0, 0, 0.25)) {
  -webkit-box-shadow: $shadow2;
  -moz-box-shadow: $shadow2;
  box-shadow: $shadow2;
}

@mixin box-shadow3($shadow3: 0px 20px 40px 10px rgba(0, 0, 0, 0.07)) {
  -webkit-box-shadow: $shadow3;
  -moz-box-shadow: $shadow3;
  box-shadow: $shadow3;
}

@mixin box-shadow4($shadow4: 0px 2px 0px -2px rgba(0, 0, 0, 0.17)) {
  -webkit-box-shadow: $shadow4;
  -moz-box-shadow: $shadow4;
  box-shadow: $shadow4;
}

@mixin box-shadow5($shadow5: 0px 2px 4px -2px rgba(0, 0, 0, 0.17)) {
  -webkit-box-shadow: $shadow5;
  -moz-box-shadow: $shadow5;
  box-shadow: $shadow5;
}

@mixin box-shadow-mini($shadow-mini: 0px 3px 5px rgb(0 0 0 / 4%)) {
  -webkit-box-shadow: $shadow-mini;
  -moz-box-shadow: $shadow-mini;
  box-shadow: $shadow-mini;
}

@mixin form-shadow-orange($shadow-orange: 0px 0px 0px 4px rgba(240, 84, 84, 10%)) {
  -webkit-box-shadow: $shadow-orange;
  -moz-box-shadow: $shadow-orange;
  box-shadow: $shadow-orange;
}

@mixin form-shadow-blue($shadow-blue: 0px 0px 0px 4px rgba(22, 29, 111, 10%)) {
  -webkit-box-shadow: $shadow-blue;
  -moz-box-shadow: $shadow-blue;
  box-shadow: $shadow-blue;
}

/* -------------------------------------
                Transitioin
----------------------------------------*/
@mixin transition($transition: all .3s) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin transform ($transform: scale(1.2)) {
  -ms-transform: $transform;
  -moz-transform: $transform;
  -webkit-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@mixin btn-options {
  position: absolute;
  right: 0px;
  transition: .3s;
  z-index: 100;

  .btn {
    width: 30px;
    min-width: 10px;
    height: 30px;
    margin-left: 6px;
    padding: 0px !important;
    font-size: 16px;
  }
}

@mixin avatar($size) {
  width: $size;
  height: $size;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid $dark-gray-10;
  background-position: center !important;
  background-size: cover !important;
  cursor: pointer;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  &.active {
    border-color: $dark-orange;
  }
}

@mixin avatar-icon($size, $font-size) {
  width: $size;
  height: $size;
  font-size: $font-size;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: $dark-orange;
  background: $dark-orange-25;
  border: 3px solid $dark-orange-10;
  text-transform: uppercase;
  display: grid;
  place-items: center;

  &.active {
    border-color: $dark-orange;
  }

  .bi::before {
    vertical-align: -2px;
  }
}

@mixin user-initials($size, $font-size) {
  width: $size;
  height: $size;
  font-size: $font-size;
  font-weight: 400;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: $dark-orange;
  background: $dark-orange-25;
  border: 3px solid $dark-orange-10;
  text-transform: uppercase;
  display: grid;
  place-items: center;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
    supported by Chrome, Edge, Opera and Firefox */

  &.active {
    border-color: $dark-orange;
  }
}

/* -------------------------------------
            Gradient Backgrounds
----------------------------------------*/

@mixin card-gradient {
  background-image: radial-gradient(circle at 57% 36%, rgba(22, 29, 111, 0.011764705882352941) 0%, rgba(22, 29, 111, 0.011764705882352941) 10%, transparent 10%, transparent 100%), radial-gradient(circle at 22% 61%, rgba(22, 29, 111, 0.011764705882352941) 0%, rgba(22, 29, 111, 0.011764705882352941) 36%, transparent 36%, transparent 100%), radial-gradient(circle at 68% 97%, rgba(22, 29, 111, 0.011764705882352941) 0%, rgba(22, 29, 111, 0.011764705882352941) 41%, transparent 41%, transparent 100%), radial-gradient(circle at 57% 89%, rgba(22, 29, 111, 0.011764705882352941) 0%, rgba(22, 29, 111, 0.011764705882352941) 30%, transparent 30%, transparent 100%), radial-gradient(circle at 39% 80%, rgba(22, 29, 111, 0.011764705882352941) 0%, rgba(22, 29, 111, 0.011764705882352941) 22%, transparent 22%, transparent 100%), radial-gradient(circle at 88% 71%, rgba(22, 29, 111, 0.011764705882352941) 0%, rgba(22, 29, 111, 0.011764705882352941) 30%, transparent 30%, transparent 100%), linear-gradient(0deg, rgb(243, 243, 248), rgb(243, 243, 248));
}

@mixin blue-gradient {
  background-color: #1E2796;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%231c258e' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%231b2386' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%2319217f' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23181f77' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23161D6F' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23182079' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%231a2283' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%231c258e' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%231e2798' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23202AA2' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
}

@mixin orange-gradient {
  background-color: #DD3F3F;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23e24747' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23e74f4f' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23ec5858' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23f06161' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23F46A6A' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23f06161' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23ec5858' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23e74f4f' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23e24747' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23DD3F3F' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
}

/* -------------------------------------
            Background Overlay
----------------------------------------*/
@mixin dark-overlay {
  &:after {
    content: "";
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}

@mixin animate-skeleton {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  z-index: 100;
  border-radius: 10px;
  background-color: $ab-color;
  background-image: -webkit-gradient(linear, left center, right center, from($ab-color), color-stop(0.2, #edeef1), color-stop(0.4, $ab-color), to($ab-color));
  background-image: -webkit-linear-gradient(left, $ab-color 0%, #edeef1 20%, $ab-color 40%, $ab-color 100%);
  background-size: 100% 100%;
  position: relative;
}

@keyframes motion {
  0% {
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }

  25% {
    -webkit-transform: translateX(-50px) scale(0.3);
    transform: translateX(-50px) scale(0.3);
  }

  50% {
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }

  75% {
    -webkit-transform: translateX(50px) scale(0.3);
    transform: translateX(50px) scale(0.3);
  }

  100% {
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }
}