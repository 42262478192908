/*-------------------------------------
                Checkbox
--------------------------------------*/

.checkbox-wrapper {
    padding-left: 28px;
    position: relative;

    .checkbox {
        position: absolute;
        left: 0px;
        top: 4px;
    }

    label {
        cursor: pointer;
    }

    &.v2 {
        width: fit-content;
        margin: 0 auto;
    }
}

.checkbox {
    --bg: transparent;
    --brdr: #d1d6ee;
    --brdr-actv: #{$dark-orange};
    --brdr-hovr: #F7A9A9;
    --dur: calc((var(--size, 2)/2) * 0.6s);
    display: inline-block;
    width: calc(var(--size, 1) * 18px);
    position: relative;

    &:after {
        content: "";
        width: 100%;
        padding-top: 100%;
        display: block;
    }

    &>* {
        position: absolute;
    }

    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
        background-color: var(--bg);
        border-radius: calc(var(--size, 1) * 4px);
        border: calc(var(--newBrdr, var(--size, 1)) * 1px) solid;
        color: var(--newBrdrClr, var(--brdr));
        outline: none;
        margin: 0;
        padding: 0;
        transition: all calc(var(--dur) / 3) linear;

        &:hover,
        &:checked {
            --newBrdr: calc(var(--size, 1) * 2);
        }

        &:hover {
            --newBrdrClr: var(--brdr-hovr);
        }

        &:checked {
            --newBrdrClr: var(--brdr-actv);
            transition-delay: calc(var(--dur) /1.3);

            &+svg {
                --dashArray: 16 93;
                --dashOffset: 109;
            }
        }
    }

    svg {
        fill: none;
        left: 0;
        pointer-events: none;
        stroke: var(--stroke, var(--brdr-actv));
        stroke-dasharray: var(--dashArray, 93);
        stroke-dashoffset: var(--dashOffset, 94);
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px;
        top: 0;
        transition: stroke-dasharray var(--dur), stroke-dashoffset var(--dur);
    }

    svg,
    input {
        display: block;
        height: 100%;
        width: 100%;
    }
}

.checkbox-label {
    margin-top: -18px;
    margin-left: 28px;
    cursor: pointer;
}