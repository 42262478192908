/*-------------------------------------
            Dropdown Mneu
--------------------------------------*/

.dropdown-menu {
    border: 0px;
    padding: 20px 0px;
    border-radius: 10px;
    @include box-shadow2;

    .dropdown-item {
        font-size: 12px;
        padding: 4px 26px;
        color: $dark-gray-75;
        font-weight: 400;
        line-height: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;

        b,
        small {
            color: $dark-gray-75;
        }

        .bi {
            font-size: 16px;
            margin-right: 10px;
        }

        .svg-icon {
            width: 16px;
            height: 16px;
            margin-right: 10px;
            fill: $dark-gray-75;
        }

        &.active {
            color: $dark-orange;
            background-color: transparent;
            cursor: default;

            b {
                color: $dark-orange;
            }

            small {
                color: $dark-orange;
                line-height: 10px;
            }

            &:hover {
                color: $dark-orange;
                background-color: transparent;
            }
        }

        &:hover {
            text-decoration: none;
            background-color: $form-bg;
            outline: none;
            box-shadow: none;
        }

        &.disabled {
            color: $dark-gray-25;
            cursor: default;

            i {
                color: $dark-gray-25 !important;
            }
        }
    }

    .dropdown-divider {
        border-color: $dark-gray-10;
    }
}