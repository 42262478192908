/* -------------------------------------
               File Upload
----------------------------------------*/

.uploader {
  display: block;
  clear: both;
  margin: 0 auto;
  width: 100%;

  .py-3 img {
    width: 100px !important;
  }

  label {
    width: 100%;
    padding: 2rem 1.5rem;
    text-align: center;
    background: #fff;
    border-radius: 15px;
    border: 3px dashed $dark-gray-10;
    transition: all .2s ease;
    user-select: none;

    &:hover {
      border-color: $dark-orange;
    }

    &.hover {
      border: 3px solid $dark-orange;
      box-shadow: inset 0 0 0 6px #eee;
    }
  }

  input[type="file"] {
    display: none;
  }

}
