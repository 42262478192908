/*-------------------------------------
            Bootstrap Tooltip
--------------------------------------*/

.tooltip-inner {
    background-color: $dark-gray;
    color: white;
    font-size: 12px;
    padding: 10px 14px;
    border-radius: 8px;
    text-align: left;
    @include box-shadow;
}

.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: $dark-gray;
}

.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: $dark-gray;
}

.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: $dark-gray;
}

.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: $dark-gray;
}

.toast-top-right {
    top: 80px;
}

.tooltip.show {
    opacity: 1;
}


/*-------------------------------------
             Custom Tooltip
--------------------------------------*/

.custom-tooltip {
    position: relative;

    .tooltip-content {
        width: 200px;
        position: absolute;
        z-index: 9999;
        text-align: left;
        background-color: white;
        color: $red-color;
        opacity: 0;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 26px auto;
        align-items: center;
        padding: 10px;
        border: 1px solid $form-bg;
        @include box-shadow2;
        @include transition;

        img {
            width: 20px;
        }

        &::after {
            content: "";
            position: absolute;
            width: 0px;
            height: 0px;
            border: 10px solid transparent;
        }

        &.content-left,
        &.content-right {
            top: 50%;
            transform: translateY(-50%);
        }

        &.content-left {
            right: 95%;

            &::after {
                left: 100%;
                border-left: 10px solid white;
            }
        }

        &.content-right {
            left: 95%;

            &::after {
                right: 100%;
                border-right: 10px solid white;
            }
        }

        &.content-top,
        &.content-bottom {
            left: 50%;
            transform: translateX(-50%);

            &::after {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &.content-top {
            bottom: 110%;

            &::after {
                top: 100%;
                border-top: 10px solid white;
            }
        }

        &.content-bottom {
            top: 110%;

            &::after {
                bottom: 100%;
                border-bottom: 10px solid white;
            }
        }
    }

    .tooltip-text {
        display: inline-block;
        white-space: normal;
        font-size: 10px;
        line-height: 16px;
        color: $red-color;
        padding: 2px;
        padding-right: 4px;

        &.text-justify {
            text-align: justify;
            text-justify: inter-word;
        }

        a {
            display: inline-block;
            margin-top: 6px;
            text-decoration: underline;
            color: $dark-blue;
            font-size: 12px;
            line-height: 16px;
        }
    }

    &:hover .tooltip-content {
        opacity: 1;
    }

}


/*-------------------------------------
             QR Preview Tooltip
--------------------------------------*/

.qr-preview-tooltip {
    position: relative;

    &.bs-tooltip-top {
        .tooltip-arrow::before {
            @include box-shadow2;
            border-top-color: white;
        }
    }

    &.bs-tooltip-start {
        .tooltip-arrow::before {
            @include box-shadow2;
            border-left-color: white;
        }
    }

    &.bs-tooltip-end {
        .tooltip-arrow::before {
            @include box-shadow2;
            border-right-color: white;
        }
    }

    &.bs-tooltip-bottom {
        .tooltip-arrow::before {
            @include box-shadow2;
            border-bottom-color: white;
        }
    }

    .tooltip-inner {
        background-color: white;
        @include box-shadow2;
    }
}