@import "~@ng-select/ng-select/themes/default.theme.css";
@import "scss/variables";

@import "scss/components/accordion";
@import "scss/components/alerts";
@import "scss/components/animation";
@import "scss/components/badge";
@import "scss/components/breadcrumb";
@import "scss/components/buttons";
@import "scss/components/dropdowns";
@import "scss/components/swiper";
@import "scss/components/flickity";
@import "scss/components/modal";
@import "scss/components/navs-tabs";
@import "scss/components/offcanvas";
@import "scss/components/pagination";
@import "scss/components/tooltips";

@import "scss/forms/checkbox";
@import "scss/forms/file-upload";
@import "scss/forms/form-control";
@import "scss/forms/ng-select";
@import "scss/forms/radio";
@import "scss/forms/toggle";

@import "scss/global";
@import "scss/typography";