/*-------------------------------------
                 Global
--------------------------------------*/

*:focus {
  outline: none !important;
}

html {
  // overflow-y: scroll;
  scroll-behavior: smooth;
}

body {
  font-family: 'Lato', sans-serif !important;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $dark-gray-10;
  margin: 1em 0;
  padding: 0;
  opacity: 1;
}

table {
  font-size: 14px;

  th {
    font-size: 12px;
    color: $dark-gray-50;
    border-bottom: 2px solid $dark-gray-10 !important;
  }

  td {
    color: $dark-gray-75;
    border-color: $dark-gray-10 !important;
    font-size: 14px;

    a {
      font-size: 14px;
      line-height: 20px;

      &.sm {
        font-size: 12px;
      }
    }

    .btn-sm {
      padding: 4px 12px !important;
    }

  }
}

.banner-wrapper {
  font-size: 12px;
  border-radius: 6px;
  padding: 10px 20px;

  &.banner-wrapper-success {
    color: $light-green;
    background-color: $light-green-10;

    a.aright {
      color: $light-green;
    }
  }

  &.banner-wrapper-warning {
    color: $orange;
    background-color: $orange-10;

    a.aright {
      color: $orange;
    }
  }

  &.banner-wrapper-info {
    color: $dark-blue;
    background-color: $dark-blue-10;

    a.aright {
      color: $dark-blue;
    }
  }

  a {
    min-width: 100px;
    font-size: 12px;
    padding: 3px 12px;
    border-radius: 6px;
    text-align: center;
    background-color: white;
    @include box-shadow-mini;
  }
}

.bi {

  &.bi-sm,
  &.bi-md,
  &.bi-lg,
  &.bi-xl {
    color: $dark-gray-75;
  }

  &.bi-sm {
    font-size: 16px;
  }

  &.bi-md {
    font-size: 18px;
  }

  &.bi-lg {
    font-size: 20px;
  }

  &.bi-xl {
    font-size: 24px;
  }
}

.container {
  &.w-sm {
    max-width: 992px;
    margin: 0px auto;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.contents {
  background-color: white;
  border-radius: 15px;
  padding: 40px;
}

.toggle-box {
  border: 2px solid $dark-gray-10;
  border-radius: 12px;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  margin-bottom: 15px;

  b {
    font-size: 14px;
  }

  .toggleWrapper {
    padding-top: 3px;
  }
}

.image-wrapper {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  padding: 50px 15px;

  img {
    width: 50%;

    &.lg {
      width: 70%;
    }

    &.sm {
      width: 40%;
    }
  }

  svg {
    width: 50%;

    &.lg {
      width: 70%;
    }

    &.sm {
      width: 40%;
    }
  }

  p {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  h6 {
    margin-top: 20px;
  }
}

.image-wrapper-2 {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  padding: 50px 15px;

  img {
    width: 50%;

    &.lg {
      width: 70%;
    }

    &.sm {
      width: 40%;
    }
  }
}

.file-wrapper {
  border: 2px solid $dark-blue-10;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .file-body {
    display: flex;
    align-items: center;
    padding-right: 15px;
  }

  .file-name {
    display: flex;
    flex-direction: column;
  }

  b {
    font-size: 14px;
    color: $dark-blue !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-word;

    &.sm {
      font-size: 13px !important;
    }
  }

  small {
    color: $dark-blue-50;
  }

  .bi-x {
    font-size: 24px;
    color: $dark-gray-25;
    cursor: pointer;
    @include transition;

    &:hover {
      color: $dark-gray-50;
    }
  }
}

.disable-div {
  opacity: .2;
}

.fade-div {
  opacity: .5;
}

.disable-div,
.processing {
  pointer-events: none !important;

  input,
  textarea,
  button {
    pointer-events: none !important;
  }
}

.dropdown-col {
  position: relative;

  .slash {
    position: absolute;
    right: 0px;
    top: 10px;
  }
}

.progress {
  .green {
    background-color: $light-green;
  }

  .orange {
    background-color: $orange;
  }

  .red {
    background-color: $red-color;
  }
}

span.text-muted {
  color: $dark-gray-50 !important;
}

svg:focus {
  outline: none;
}

.crop-options {
  .btn {
    width: 30px;
    min-width: 10px;
    height: 30px;
    margin-left: 6px;
    padding: 0px !important;

    .bi {
      font-size: 16px;
    }
  }
}

.icon-bg {
  background-color: $dark-orange-10;
  width: 70px;
  height: 70px;
  border-radius: 100px;
  margin: auto;
  display: grid;
  place-items: center;

  .bi {
    color: $dark-orange;
    font-size: 36px;
  }

  &.bg-green {
    background-color: $light-green-10;

    .bi {
      color: $light-green;
    }
  }

  &.bg-orange {
    background-color: $orange-10;

    .bi {
      color: $orange;
    }
  }

  &.bg-blue {
    background-color: $dark-blue-10;

    .bi {
      color: $dark-blue;
    }
  }

}


/*-------------------------------------
             NOT FOUND
--------------------------------------*/

.not-found-wrapper {
  background-image: radial-gradient(circle at 85% 1%, rgba(240, 84, 84, 0.03) 0%, rgba(240, 84, 84, 0.03) 96%, transparent 96%, transparent 100%), radial-gradient(circle at 14% 15%, rgba(240, 84, 84, 0.03) 0%, rgba(240, 84, 84, 0.03) 1%, transparent 1%, transparent 100%), radial-gradient(circle at 60% 90%, rgba(240, 84, 84, 0.03) 0%, rgba(240, 84, 84, 0.03) 20%, transparent 20%, transparent 100%), radial-gradient(circle at 79% 7%, rgba(240, 84, 84, 0.03) 0%, rgba(240, 84, 84, 0.03) 78%, transparent 78%, transparent 100%), radial-gradient(circle at 55% 65%, rgba(240, 84, 84, 0.03) 0%, rgba(240, 84, 84, 0.03) 52%, transparent 52%, transparent 100%), linear-gradient(135deg, rgb(251, 212, 212), rgb(254, 246, 246));
  width: 100%;
  min-height: 100vh;

  .not-found-contents {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    padding: 80px 15px;

    img {
      width: 70%;
    }

    h4 {
      margin-top: 20px;
    }
  }
}

/*-------------------------------------
              Quill Editor
--------------------------------------*/

.job-description {

  b,
  strong {
    font-size: 14px;
    line-height: 20px;
    color: $dark-gray;
  }

  p {
    line-height: 20px;
  }

  ul li {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0px;
  }
}

.quill-editor-wrapper {

  .ql-toolbar {
    border-color: $border-color;
    border-radius: 4px 4px 0px 0px;
  }

  .ql-container {
    border-color: $border-color;
    border-radius: 0px 0px 4px 4px;
    min-height: 250px;
    position: relative;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      color: $dark-gray;
    }
  }

  .ql-picker-label {
    outline: none !important;
    border: none !important;

    &:focus {
      outline: none !important;
      border: none !important;
      color: black !important;
    }
  }

  .ql-picker-item:focus {
    outline: none !important;
  }

  .ql-picker-options {
    border: none !important;
    border-radius: 10px;
    padding: 20px;
    -webkit-box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.17) !important;
    -moz-box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.17) !important;
    box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.17) !important;
  }

  .ql-tooltip {
    position: absolute;
    left: 50% !important;
    top: 0px !important;
    transform: translate(-50%) !important;
    border: none !important;
    border-radius: 10px;
    padding: 10px 20px;
    -webkit-box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.17) !important;
    -moz-box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.17) !important;
    box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.17) !important;

    input {
      border-color: $border-color;
      border-radius: 4px;
      padding: 10px !important;

      &:focus {
        border-color: $dark-orange !important;
        outline: 0;
        box-shadow: none !important;
      }
    }
  }
}

/*-------------------------------------
              ngx Datepicker
  --------------------------------------*/

.md-drppicker {
  border-radius: 10px !important;
  padding: 15px !important;
  -webkit-box-shadow: 0px 7px 19px -6px rgba(0, 0, 0, 0.25) !important;
  -moz-box-shadow: 0px 7px 19px -6px rgba(0, 0, 0, 0.25) !important;
  box-shadow: 0px 7px 19px -6px rgba(0, 0, 0, 0.25) !important;

  th,
  td {
    color: $dark-gray-75 !important;
  }
}

.md-drppicker td.active,
.md-drppicker td.active:hover {
  background-color: $dark-orange !important;
  color: white !important;
}

.md-drppicker.shown.drops-down-right {
  margin-top: 6px;
}


/*-------------------------------------
                Search Input
  --------------------------------------*/

.search-input,
.date-input {
  position: relative;

  .form-control {
    padding-right: 34px;

    &.is-invalid,
    &.ng-invalid {
      background-position: right calc(2.5em + 0.1875rem) center;
    }
  }

  .bi {
    font-size: 16px;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: $dark-gray-50;
    cursor: pointer;
  }

  .spinner-border {
    position: absolute;
    top: 30%;
    right: 15px;
    cursor: pointer;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

// Google credential picker
#credential_picker_container,
#credential_picker_iframe {
  display: none !important;
}

.search-result {
  background-color: white;
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: none;
  @include box-shadow;

  td {
    list-style: none;
    cursor: pointer;
    font-size: 12px;
    line-height: 30px;
    color: $dark-gray-75;
    font-weight: 300;
  }
}

.roll-on-hover {
  &:hover {
    .bi {
      transform: rotate(360deg);
      transition: 1s;
    }
  }
}

.line {
  text-align: center;
  padding: 30px 0px;

  span {
    background: white;
    padding: 4px 10px;
    font-size: 12px;
    color: $dark-gray-50;
  }

  hr {
    margin-top: -11px;
  }
}

.link-analytics {
  .top {
    border: 1px solid $dark-gray-10;
    border-radius: 10px;
    font-size: 10px;
    padding: 10px;
    margin-bottom: 10px;
    color: $dark-gray-50;
    @include box-shadow;

    .title {
      font-size: 12px;
      font-weight: 500;
      color: $dark-gray;
      margin-bottom: 20px;

      .bi {
        margin-right: 4px;
        width: 14px;
        height: 14px;
      }
    }

    .p-bar {
      position: relative;
      margin-top: 12px;
      margin-bottom: 12px;

      span {
        margin-bottom: 6px;
        display: inline-block;
        color: $dark-gray-75;

        &.percentage {
          position: absolute;
          right: 0px;
          font-weight: 600;
        }
      }

      .progress {
        height: 5px !important;

        .progress-bar {
          background-color: $dark-blue;
        }
      }
    }
  }
}

.cdk-drag-placeholder {
  opacity: 0;
}

.keep-rotating {
  // animation: rotation 2s infinite linear;
  -webkit-animation: rotation 2s infinite linear;
  -moz-animation: rotation 2s infinite linear;
  -o-animation: rotation 2s infinite linear;
  -ms-animation: rotation 2s infinite linear;
  animation: rotation 2s infinite linear;
  display: block;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

.mouse-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.radius-all {
  border-radius: 50%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.slider {
  .slider-handle {
    background: $dark-orange !important;
  }
}

.delay-0 {
  animation-delay: 0s;
}

.delay-1 {
  animation-delay: 0.2s;
}

.delay-2 {
  animation-delay: 0.4s;
}

.delay-3 {
  animation-delay: 0.6s;
}

.delay-4 {
  animation-delay: 0.8s;
}

.delay-5 {
  animation-delay: 1s;
}

.delay-6 {
  animation-delay: 1.2s;
}

.delay-7 {
  animation-delay: 1.4s;
}

.delay-8 {
  animation-delay: 1.6s;
}

.delay-9 {
  animation-delay: 1.8s;
}

.delay-10 {
  animation-delay: 2s;
}

.delay-11 {
  animation-delay: 2.2s;
}

.delay-12 {
  animation-delay: 2.4s;
}

.delay-13 {
  animation-delay: 2.6s;
}

.delay-14 {
  animation-delay: 2.8s;
}

.delay-15 {
  animation-delay: 3s;
}

.delay-16 {
  animation-delay: 3.2s;
}

.delay-17 {
  animation-delay: 3.4s;
}

.delay-18 {
  animation-delay: 3.6s;
}

.delay-19 {
  animation-delay: 3.8s;
}

.delay-20 {
  animation-delay: 4s;
}

.transition-slow {
  transition-duration: .5s;
}

.no-visibility {
  visibility: hidden;
}

.vanilla-antd-message-icon.info {
  background: $dark-orange !important
}

.vanilla-antd-message-icon.success {
  background: $light-green !important
}

.vanilla-antd-message-icon.error {
  background: $red-color !important
}

.vanilla-antd-message-icon.warn {
  background: $orange !important
}

.highcharts-tooltip-container {
  z-index: 9999 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/*-------------------------------------
             SCROLLBAR
--------------------------------------*/

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $dark-gray-10;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $dark-gray-25;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $dark-gray-50;
  @include transition
}


@supports (scrollbar-color: $dark-gray-25 $dark-gray-10) {

  * {
    scrollbar-color: $dark-gray-25 $dark-gray-10;
    scrollbar-width: thin;
  }
}





/* -------------------------------------
               Media Query
----------------------------------------*/
@media (min-width: 768px) {

  .search-input,
  .date-input {
    &.fixed-width {
      max-width: 300px;
      min-width: 300px;
    }
  }
}


/* -------------------------------------
               Media Query
----------------------------------------*/
@media (max-width: 576px) {

  .contents {
    padding: 30px 20px;
  }

  .hidden-on-mobile {
    display: none !important;
  }

  .vanilla-antd-message {
    left: 25%;
    width: 100%;

    .vanilla-antd-content-box {
      left: 25%;
    }
  }
}