/*-------------------------------------
                 Alert
--------------------------------------*/

.alert {
    font-size: 12px;
    padding: 10px 15px;

    .bi {
        font-size: 18px;
        margin-right: 10px;
    }

    strong {
        font-size: 12px;
    }

    &.alert-danger {
        border-left: 4px solid $dark-orange !important;
        color: $dark-orange;
        border-color: $dark-orange-25;
        background-color: $dark-orange-5;
    }

    &.alert-success {
        border-left: 4px solid $light-green !important;
        color: $light-green;
        border-color: $light-green-25;
        background-color: $light-green-5;
    }

    &.alert-warning {
        border-left: 4px solid $orange !important;
        color: $orange;
        border-color: $orange-25;
        background-color: $orange-5;
    }

    &.alert-info {
        border-left: 4px solid $light-blue !important;
        color: $light-blue;
        border-color: $light-blue-25;
        background-color: $light-blue-5;
    }
}

.toast-top-right {
    top: 85px !important;
}

#toast-container>div {
    opacity: 1 !important;
    border-radius: 5px !important;
    -webkit-box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.17) !important;
    -moz-box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.17) !important;
    box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.17) !important;
}

.toast-close-button {
    opacity: 0 !important;
}

.toast-success {
    background-color: $light-green !important;
}

.toast-error {
    background-color: $red-color !important;
}

.toast-info {
    background-color: $light-blue !important;
}

.toast-warning {
    background-color: $orange !important;
}