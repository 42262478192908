/*-------------------------------------
            Global NG Select
--------------------------------------*/

.ng-select-container {
    border: 1px solid $form-bg !important;
    background-color: $form-bg !important;
    border-radius: 8px !important;
    font-size: 14px;
    padding: 20px 6px;
    padding-bottom: 21px;
    cursor: pointer !important;
    @include transition;

    .bi {
        font-size: 16px;
        color: $dark-gray-75;
        margin-right: 4px;
        margin-right: 10px;
        margin-top: -2px !important;
    }

    &:hover {
        background-color: white !important;
        -webkit-box-shadow: 0 0 0 4px rgb(240, 84, 84, 10%) !important;
        box-shadow: 0 0 0 4px rgb(240, 84, 84, 10%) !important;
    }

    &:focus {
        border-color: $dark-orange-50 !important;
        background-color: white !important;
        -webkit-box-shadow: 0 0 0 4px rgb(240, 84, 84, 10%) !important;
        box-shadow: 0 0 0 4px rgb(240, 84, 84, 10%) !important;
    }

    .ng-input>input {
        margin: 4px 5px;
    }
}

.ng-dropdown-panel {
    margin-top: 10px !important;
    z-index: 600 !important;
    padding: 10px 0px;
    border-radius: 8px !important;
    font-size: 14px;
    border: 1px solid $form-bg !important;
    -webkit-box-shadow: 0px 3px 5px rgb(0 0 0 / 4%) !important;
    -moz-box-shadow: 0px 3px 5px rgb(0 0 0 / 4%) !important;
    box-shadow: 0px 3px 5px rgb(0 0 0 / 4%) !important;

    .bi {
        font-size: 16px;
        color: $dark-gray-75;
        margin-right: 8px;
        margin-top: -2px !important;
    }
}

.ng-option {
    background-color: transparent !important;
    color: $dark-gray-75 !important;
    font-weight: 400 !important;
    padding: 8px 20px !important;
    padding-bottom: 10px !important;

    &:hover {
        background-color: $form-bg !important;
        transition: 0.2s !important;
    }
}

.ng-option-selected {
    background-color: transparent !important;
    color: $dark-orange !important;

    &.ng-option-marked {
        background-color: transparent !important;
        color: $dark-orange;
    }

    .bi {
        color: $dark-orange;
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
    font-weight: 400 !important;
}

.ng-value-icon.left {
    border-right: 1px solid $dark-gray-10 !important;
}

.ng-option-marked {
    background-color: #f8f9fa !important;
    transition: 0.2s !important;
}

.ng-clear-wrapper {
    padding-top: 3px;
}

.ng-select,
.ng-select-searchable,
.ng-select-container,
.ng-select-multiple {
    box-shadow: none !important;
    outline: none !important;
    cursor: pointer !important;
}

.ng-select.ng-select-disabled>.ng-select-container {
    background-color: $dark-gray-10 !important;
    opacity: .4;
    cursor: not-allowed !important;

    &:hover {
        box-shadow: none !important;
    }
}


/*-------------------------------------
               Ng Select Tags
  --------------------------------------*/

.tags-wrapper {

    .ng-select-container {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .ng-input>input {
        padding-top: 0px;
    }

    .ng-value {
        padding: 4px 8px !important;
        background-color: darken($dark-gray-10, 5%) !important;
        border-radius: 30px !important;
    }

    .ng-value-label {
        white-space: normal !important;
    }

    .ng-value-label,
    .ng-value-icon {
        background-color: darken($dark-gray-10, 5%) !important;
        color: $dark-gray !important;
        font-size: 12px;
        border: none !important;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        display: flex;
        align-items: center;
    }
}

/*-------------------------------------
           Filter Select Sorting
  --------------------------------------*/

.filter-select-wrapper {
    position: relative;
    min-height: 50px;

    ng-select {
        direction: rtl;
    }

    .ng-select-container {
        background-color: transparent !important;
        border: none !important;
        color: $dark-gray-75;
        cursor: pointer !important;
        position: absolute !important;
        right: 0px;
        top: 0px;
        z-index: 10;
        padding-right: 25px;
        padding-top: 0px;
        padding-bottom: 0px;
        width: fit-content !important;
        box-shadow: none !important;

        .bi::before {
            vertical-align: -3px;
        }
    }

    .ng-dropdown-panel {
        margin-top: 36px !important;
        width: 190px !important;
        right: 0px;

        .bi::before {
            vertical-align: -3px;
        }
    }

    .ng-option {
        text-align: left;
    }

    .ng-arrow-wrapper {
        display: none;
    }

    .filter {
        font-size: 18px;
        color: $dark-gray-75;
        position: absolute;
        right: 0px;
        top: 6px;
    }

    &.lg-width {
        .ng-dropdown-panel {
            width: 210px !important;
        }
    }
}