/*-------------------------------------
                Toggle
--------------------------------------*/

.toggleWrapper {
    position: relative;

    input {
        position: absolute;
        left: -99em;

        &:checked+.toggle {
            background: $dark-orange-10;
        }

        &:checked+.toggle .toggle_handler {
            background-color: $dark-orange;
            width: 20px;
            transform: translateX(19px);
        }
    }

    .toggle {
        border-radius: 50px;
        cursor: pointer;
        display: inline-block;
        position: relative;
        width: 46px;
        height: 26px;
        padding: 3px !important;
        background: $dark-gray-10;

        &::before,
        &::after {
            position: absolute;
            // z-index: 2;
            transition: all 200ms;
        }
    }

    .toggle_handler {
        display: inline-block;
        position: relative;
        // z-index: 1;
        background: $dark-gray-25;
        width: 20px;
        height: 20px;
        border-radius: 50px;
        transition: all 200ms;
    }
}