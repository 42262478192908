/*-------------------------------------
                Buttons
--------------------------------------*/

.btn {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 8px;
  box-shadow: none;
  position: relative;
  min-width: 80px;

  i {
    font-style: normal;
  }

  &.btn-round {
    border-radius: 50px;
  }

  &.btn-block {
    width: 100%;
  }

  &:focus {
    box-shadow: none;
  }

  &.dropdown-toggle-split {
    min-width: 10px;
  }
}

.btn-sm {
  padding: 7px 15px;
  font-size: 12px;
  border-radius: 6px;

  &.dropdown-toggle-split {
    padding: 7px 14px;
  }
}

.btn-lg {
  padding: 14px 30px;
  font-size: 16px;
}

.dropdown-toggle-split {
  padding: 9px 14px;
}

.btn-primary,
.btn-secondary,
.btn-success,
.btn-warning,
.btn-danger,
.btn-info {
  color: white;

  &:hover {
    color: white;
  }

  &:focus {
    color: white;
  }

  &:disabled {
    color: white;
  }
}

.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-success,
.btn-outline-warning,
.btn-outline-danger,
.btn-outline-info {

  &:hover {
    color: white;
  }

  &.dropdown-toggle {
    &:focus {
      color: white;
    }
  }
}

.btn-light {
  background-color: white;
}

.btn-outline-light {
  color: white;
  border-color: white;
}

.btn-secondary-light {
  color: $dark-orange;
  background-color: $dark-orange-10;

  &:hover {
    color: $dark-orange;
    background-color: $dark-orange-25;
  }
}

.btn-secondary-shadow {
  -webkit-box-shadow: rgb(125, 129, 222, .3) 0px 20px 50px 0px !important;
  box-shadow: rgb(125, 129, 222, .3) 0px 20px 50px 0px !important;
}

.btn-group-full {
  display: grid;
  grid-template-columns: 5fr 1fr;
}