/*-------------------------------------
               ACCORDION
--------------------------------------*/

.accordion-wrapper {

    .accordion-item {
        border: none;
        margin-top: 20px;
        background-color: white;
        border-radius: 15px;

        .accordion-button {
            border: none !important;
            padding: 20px 40px;
            font-size: 16px;
            font-weight: 700;
            box-shadow: none;
            background-color: transparent;

            &:focus {
                box-shadow: none;
            }

            .bi {
                font-size: 22px;
                margin-right: 12px;
            }

            svg {
                width: 22px;
                height: 22px;
                margin-right: 12px;
            }
        }

    }

    .accordion-collapse {
        border: none !important;
        border-radius: 15px;

        .accordion-body {
            padding: 40px;
            padding-top: 0px;
        }
    }

    .accordion-button:not(.collapsed) {

        h5,
        b,
        small {
            color: $dark-orange;
        }

        svg {
            fill: $dark-orange;
        }
    }

    &.v2 {
        .accordion-item {
            border: 2px solid $dark-gray-10;
        }
    }
}


/* -------------------------------------
               Media Query
----------------------------------------*/
@media (max-width: 576px) {
    .accordion-wrapper {

        .accordion-item {
            .accordion-button {
                padding: 20px 30px !important;
            }

        }

        .accordion-collapse {

            .accordion-body {
                padding: 30px !important;
                padding-top: 0px !important;
            }
        }
    }
}