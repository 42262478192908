/*-------------------------------------
               Pagination
--------------------------------------*/

.pagination {
    .page-item {
        .page-link {
            color: $dark-gray-75;
            border: 1px solid $dark-gray-10;
            border-radius: 5px;
            font-size: 12px;
            padding: 4px 10px;
            margin: 0px 2px;

            &:hover {
                background-color: $dark-blue-10;
                border-color: $dark-blue-10;
                color: $dark-blue;
            }
        }

        &.active {
            .page-link {
                background-color: $dark-blue;
                color: white !important;
                border-color: $dark-blue;
            }
        }

        &.disabled {
            .page-link {
                color: $dark-gray-25;
            }
        }
    }

    &.v2 {
        .page-item {
            .page-link {
                border: none;
                @include box-shadow;
            }
        }
    }
}