.breadcrumb {
    background-color: transparent;
    padding: 0px;

    .breadcrumb-item {
        font-size: 26px;
        line-height: 26px;
        font-weight: 700;
        color: black;
        padding-right: 6px;
        padding-left: 0px;

        a {
            color: black;
            font-size: 26px;
            line-height: 26px;
            font-weight: 700;
            padding-right: 6px;
            cursor: pointer;

            &:hover {
                opacity: .7;
            }
        }

        &.active {
            color: $dark-gray-50;
        }

        .bi-chevron-right {
            font-size: 20px !important;
            line-height: 26px !important;
            color: $dark-gray-50 !important;
        }

        .bi {
            font-size: 26px;
            color: black;
        }

        &::before {
            display: none;
        }
    }
}



/* -------------------------------------
               Media Query
----------------------------------------*/
@media (max-width: 576px) {
    .breadcrumb {

        .breadcrumb-item {
            font-size: 18px;
            line-height: 18px;

            a {
                font-size: 18px;
                line-height: 18px;
            }

            .bi-chevron-right {
                font-size: 16px !important;
                line-height: 18px !important;
            }

            .bi {
                font-size: 18px;
            }
        }
    }
}