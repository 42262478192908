/*-------------------------------------
            Input & Select
--------------------------------------*/

.form-control,
.form-select,
#card-element {
    font-size: 14px;
    padding: 10px 16px;
    font-weight: 400;
    border-radius: 8px;
    color: $dark-gray;
    border-color: $form-bg;
    background-color: $form-bg;
    @include transition;

    &::placeholder {
        color: $dark-gray-50;
    }

    &:hover,
    &:focus {
        background-color: white;
        @include form-shadow-orange;
    }

    &:focus {
        background-color: white;
        border-color: $dark-orange-50;
    }

    &:disabled,
    &[readonly] {
        background-color: $dark-gray-10;
        opacity: .4;

        &:hover {
            box-shadow: none;
        }
    }
}

.form-control-sm {
    font-size: 12px;
    padding: 8px 14px;
}

.input-group-text {
    background-color: darken($form-bg, 5%);
    border-color: darken($form-bg, 5%);
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    font-size: 14px;
}

select:focus {
    outline: none !important;
}

#card-element {
    padding: 12px 16px;
}

.custom-file-wrapper {
    border: 2px dashed $dark-gray-10;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;

    input[type='file'] {
        display: none;
    }

    label {
        background-color: $dark-orange;
        padding: 12px 30px;
        font-size: 14px;
        border-radius: 8px;
        color: white;
        margin-bottom: 0px;
        cursor: pointer;

        &.sm {
            font-size: 12px;
            padding: 8px 20px;
        }
    }
}