/*-------------------------------------
             Radio Button
--------------------------------------*/
:root {
    --border-color: #{$dark-orange};
}


[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;

    &+label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: $dark-gray-75;
        font-weight: 400;
        font-size: 12px;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            border: 2px solid $dark-gray-25;
            border-radius: 50%;
            background: #fff;
        }

        &:after {
            content: '';
            width: 10px;
            height: 10px;
            background: $dark-orange;
            position: absolute;
            top: 5px;
            left: 5px;
            border-radius: 50%;
            transition: all 0.2s ease;
        }
    }
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    transform: scale(0);
}

[type="radio"]:checked {
    &+label {
        &:before {
            border-color: var(--border-color);
        }

        &:after {
            opacity: 1;
            transform: scale(1);
            background-color: var(--border-color);
        }
    }
}