.workspace-wrapper {
    .custom-swiper-wrapper {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        max-width: 100%;
        gap: 0.15rem;
        margin-left: 4px;

        .swiper,
        .swiper-container {
            margin-left: unset !important;
            overflow-x: auto !important;
            max-width: 85%;
            z-index: 0;
            padding-top: 4px;

            .swiper-slide {
                width: auto !important;

                &:first-child {
                    margin-left: 10px !important;
                }

                &:last-of-type {
                    margin-right: 50px !important;
                }
            }

            &::part(button-next),
            &::part(button-prev) {
                visibility: hidden !important;
            }
        }

        .custom-swiper-button-next,
        .custom-swiper-button-prev {
            padding: 5px 6px;
            min-width: unset;
            border-width: 1px;
            border-radius: 8px;
            color: white;
            background-color: $dark-orange;
            border-color: $dark-orange-75;
        }

        .custom-swiper-button-next {
            position: absolute;
            right: 4px;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

    }
}

.sidebar-sm {
    .workspace-wrapper {
        .custom-swiper-wrapper {

            .swiper,
            .swiper-container {
                margin-left: unset !important;
                overflow-x: auto !important;
                max-width: 85%;
                z-index: 0;
                padding-top: 4px;

                .swiper-slide {
                    width: auto !important;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-of-type {
                        margin-right: 200px !important;
                    }
                }

                &::part(button-next),
                &::part(button-prev) {
                    visibility: hidden !important;
                }
            }

            .custom-swiper-button-prev {
                display: none;
            }

            .custom-swiper-button-next {
                position: absolute;
                top: 8px;
                left: 50px;
                text-align: left;
            }

        }
    }
}

.restaurant-tabs {

    .custom-swiper-wrapper {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        gap: 8px;

        .swiper,
        .swiper-container {
            margin-left: unset !important;
            overflow-x: auto !important;
            max-width: 100%;
            z-index: 0;
            padding-top: 4px;

            .swiper-slide {
                width: auto !important;

                &:first-child {
                    margin-left: 16px !important;
                }

                &:last-of-type {
                    margin-right: 16px !important;
                }
            }

            &::part(button-next),
            &::part(button-prev) {
                visibility: hidden !important;
            }
        }

        .custom-swiper-button-next,
        .custom-swiper-button-prev {
            padding: 5px 6px;
            min-width: unset;
            border-width: 1px;
            border-radius: 8px;
            background-color: white;
        }

        .custom-swiper-button-next {
            position: absolute;
            right: 14px;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

    }
}