h1,
h2,
h3,
h4,
h5,
h6 {
    color: black;
    font-weight: 700 !important;
}

h1 {
    font-size: 40px;
    line-height: 50px;

    &.lg {
        font-size: 48px;
        line-height: 60px;
    }

    &.xl {
        font-size: 86px;
        line-height: 100px;
    }

    &.double-xl {
        font-size: 128px;
        line-height: 110px;
    }

    &.triple-xl {
        font-size: 150px;
        line-height: 120px;
    }
}

h2 {
    font-size: 32px;
    line-height: 42px;
}

h3 {
    font-size: 28px;
    line-height: 36px;
}

h4 {
    font-size: 24px;
    line-height: 32px;
}

h5 {
    font-size: 20px;
    line-height: 26px;
}

h6 {
    font-size: 18px;
    line-height: 24px;
}

p {
    font-size: 14px;
    line-height: 20px;
    color: $dark-gray-75;
    font-weight: 400;

    &.lg {
        font-size: 16px;
        line-height: 22px;

        a {
            font-size: 16px;
        }
    }

    &.xl {
        font-size: 20px;
        line-height: 26px;
    }

    b {
        display: inline-block;
    }

    &.input-error {
        color: $red-color;
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 0px;
    }
}

label {
    font-size: 14px;
    color: $dark-gray-75;
    font-weight: 400;

    &.bold {
        font-weight: 700;
    }

    &.form-label {
        margin-bottom: 6px;
    }
}

a {
    color: $dark-orange;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    @include transition;

    &:hover {
        color: $dark-orange-second;
        text-decoration: none;
    }

    &.link-gray {
        color: $dark-gray-75;

        &:hover {
            color: $dark-gray;
        }
    }

    &.link-here {
        color: $dark-orange !important;

        .bi {
            color: $dark-orange;
        }

        &:hover {
            color: $dark-orange-second !important;

            .bi {
                color: $dark-orange-second;
            }
        }
    }

    i {
        font-style: normal;
    }

    &.sm {
        font-size: 12px;
    }
}

small {
    font-size: 12px;
    line-height: 17px;
    color: $dark-gray-75;
    font-weight: 400;
    display: inline-block;

    a {
        font-size: 12px;
        line-height: 17px;
    }
}

b {
    font-weight: 700;

    &.dark {
        font-size: 14px;
        line-height: 20px;
        color: $dark-gray;
    }
}

strong {
    font-weight: 700;
    font-size: 14px;
}

ul li {
    font-size: 14px;
    line-height: 20px;
    color: $dark-gray-75;
    font-weight: 400;
}

ol li {
    font-size: 14px;
    line-height: 20px;
    color: $dark-gray-75;
    font-weight: 400;

    ol {
        margin-bottom: 14px;
    }

    b {
        font-size: 14px;
    }
}








/* -------------------------------------
               Media Query
----------------------------------------*/
@media (max-width: 1400px) {

    h1 {
        font-size: 40px;
        line-height: 50px;
    
        &.lg {
            font-size: 48px;
            line-height: 60px;
        }
    
        &.xl {
            font-size: 76px;
            line-height: 90px;
        }
    
        &.double-xl {
            font-size: 90px;
            line-height: 100px;
        }
    
        &.triple-xl {
            font-size: 128px;
            line-height: 110px;
        }
    }

}







/* -------------------------------------
               Media Query
----------------------------------------*/
@media (max-width: 992px) {

    h1 {
        font-size: 32px;
        line-height: 42px;

        &.lg {
            font-size: 40px;
            line-height: 50px;
        }

        &.xl {
            font-size: 56px;
            line-height: 70px;
        }
    
        &.double-xl {
            font-size: 76px;
            line-height: 90px;
        }
    
        &.triple-xl {
            font-size: 90px;
            line-height: 100px;
        }
    }

    h2 {
        font-size: 28px;
        line-height: 36px;
    }

    h3 {
        font-size: 24px;
        line-height: 32px;
    }

    h4 {
        font-size: 20px;
        line-height: 26px;
    }
}