.theme-picker-wrapper {

  @include transition;

  .flickity-viewport {
    cursor: default !important;
  }

  .carousel-slider {
    background: $dark-blue-5;
    padding: 20px 24px;
    border-radius: 24px;

    .carousel-cell {
      position: relative;
      margin: 0 15px;

      width: 177px !important;
      height: 386px !important;
      cursor: pointer !important;

      .hover-wrapper {
        display: none;
        position: absolute;
        border-radius: 24px;
        width: 177px !important;
        height: 386px !important;
        background-color: rgba(255, 255, 255, 0.8);
      }

      &.hover-enabled {

        &.active,
        &.inactive:hover {
          .hover-wrapper {
            display: block;
            @include transition;
          }

          position: relative;
        }
      }

      .design-style {
        width: 100%;
        height: 100%;
        border-radius: 24px;
        background-size: contain !important;
        background-repeat: no-repeat !important;
      }
    }

    /* no circle */
    .flickity-button {
      background: transparent;
    }

    /* big previous & next buttons */
    .flickity-prev-next-button {
      width: 20px;
      height: 20px;
      padding: 12px;
      border-radius: 16px;
      background-color: $dark-orange;
      @include box-shadow;

      &:disabled {
        opacity: 1;

        .flickity-button-icon {
          opacity: .3;
        }
      }

      &.previous {
        left: 0px;
      }

      &.next {
        right: 0px;
      }
    }

    /* icon color */
    .flickity-button-icon {
      fill: white;
    }

    /* position dots in carousel */
    .flickity-page-dots {
      display: none;
    }
  }
}