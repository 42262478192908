/*-------------------------------------
             OFF CONVAS
--------------------------------------*/

.offcanvas {
    width: 500px !important;
    border-left: none;

    .offcanvas-header {
        background-color: $dark-orange;

        h5 {
            color: white;
            margin: 0px;
        }

        .btn-close {
            background-color: $dark-orange-25;
            border-radius: 50px;
            background-size: 12px;
            width: 14px;
            height: 14px;

            &:focus {
                box-shadow: none;
            }
        }
    }

    .offcanvas-body {
        padding: 30px;
        padding-bottom: 60px;

        #card-element {
            padding: 7px 16px;
            height: 43px;
        }
    }

    .offcanvas-footer {
        border-top: 1px solid $dark-gray-10;
    }
}


/* -------------------------------------
               Media Query
----------------------------------------*/
@media (max-width: 576px) {
    .offcanvas {
        .offcanvas-body {
            padding: 30px 16px;
            padding-bottom: 30px;
        }
    }
}