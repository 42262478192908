.nav-tabs {
    a {
        color: $dark-gray-75 !important;
        border: none !important;
        border-bottom: 3px solid transparent !important;
        font-size: 12px !important;
        padding: 10px;
        line-height: 14px;
        display: flex;
        align-items: center;

        &.active {
            background-color: transparent !important;
            color: $dark-orange !important;
            border-bottom: 3px solid $dark-orange !important;
        }

        &:hover {
            color: $dark-orange !important;
        }

        .bi {
            font-size: 16px;
            margin-right: 10px;
        }
    }
}


/*-------------------------------------
            Custom Nav Pills
--------------------------------------*/

.custom-nav {
    display: flex;
    flex-wrap: wrap;

    a {
        color: $dark-orange-75 !important;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 8px 10px;
        margin-bottom: 5px;
        @include transition;

        .bi {
            font-size: 16px;
            color: $dark-orange-75;
            margin-right: 10px;
            @include transition;
        }

        &:hover {
            color: $dark-orange !important;

            .bi {
                color: $dark-orange;
            }
        }

        &.active {
            color: white !important;
            background-color: $dark-orange;

            .bi {
                color: white;
            }
        }
    }
}